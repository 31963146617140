import { useShopApi } from "~/api/useShopApi";

export const useUserData = () => {
  const { checkShop } = useShopApi();

  const { user } = useUser();

  const hasShop = useState("hasShop", () => false);
  const errorShop = ref<any | null>(null);

  async function loadShopData() {
    if (user.value) {
      const { data: shop, error } = await checkShop();
      if (error.value) {
        errorShop.value = error.value;
      } else {
        hasShop.value = !!shop.value;
      }
    }
  }

  watchEffect(loadShopData);

  return { hasShop, errorShop, loadShopData };
};
